exports.components = {
  "component---src-components-pages-all-hotels-tsx": () => import("./../../../src/Components/Pages/AllHotels.tsx" /* webpackChunkName: "component---src-components-pages-all-hotels-tsx" */),
  "component---src-components-pages-all-jobs-tsx": () => import("./../../../src/Components/Pages/AllJobs.tsx" /* webpackChunkName: "component---src-components-pages-all-jobs-tsx" */),
  "component---src-components-pages-hotel-jobs-tsx": () => import("./../../../src/Components/Pages/HotelJobs.tsx" /* webpackChunkName: "component---src-components-pages-hotel-jobs-tsx" */),
  "component---src-components-pages-job-detail-tsx": () => import("./../../../src/Components/Pages/JobDetail.tsx" /* webpackChunkName: "component---src-components-pages-job-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-stellen-tsx": () => import("./../../../src/pages/stellen.tsx" /* webpackChunkName: "component---src-pages-stellen-tsx" */),
  "component---src-pages-storyblok-entry-full-slug-tsx": () => import("./../../../src/pages/{storyblokEntry.full_slug}.tsx" /* webpackChunkName: "component---src-pages-storyblok-entry-full-slug-tsx" */)
}

